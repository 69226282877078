/** @format */

import React, { useContext, useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Form, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { passwordValidator } from "../utils/validaciones";
import { AppContext } from "../utils/ContextWrapper";
import { navigate } from "gatsby-link";

export default function Registro() {
  const [validacion, setValidacion] = useState("");
  const [loading, setLoading] = useState(false);

  const [formularioError, setFormularioError] = useState({
    password_actual: "",
    password_confirmacion: "",
    password_nuevo: "",
  });

  const [formulario, setFormulario] = useState({
    password_actual: "",
    password_confirmacion: "",
    password_nuevo: "",
  });

  const { store } = useContext(AppContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (loading) return;
    const formulario_login = document.getElementById("login_formulario");
    if (formulario_login.checkValidity() === false) {
      setValidacion("El formulario se encuentra incompleto o con errores.");
    } else {
      setLoading(true);
      setValidacion("");
      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/change_password`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${store.usuarioAutenticado.token}`,
          "Content-type": "application/json; charset=UTF-8",
          usuario: store.usuarioAutenticado.username,
          password_actual: formulario.password_actual,
          password_nuevo: formulario.password_nuevo,
          password_confirmacion: formulario.password_confirmacion,
        },
      })
        .then(async (response) => {
          setLoading(false);
          if (response.ok) {
            navigate("/perfil/");
          } else {
            setValidacion(await response.text());
          }
        })
        .catch((err) => {
          setLoading(false);
          setValidacion("No fue posible realizar la accion, ententa nuevamente.");
        });
    }
  };

  const hadleChange = (event) => {
    setValidacion("");
    setFormularioError({
      ...formularioError,
      [event.target.name]:
        passwordValidator.test(event.target.value) === false
          ? "Ingresa al menos 8 caracteres con digito, letras mayuscula y minuscula."
          : "",
    });

    if (event.target.name === "password_confirmacion") {
      if (event.target.value === formulario.password_nuevo)
        setFormularioError({ ...formularioError, password_confirmacion: "" });
      else
        setFormularioError({
          ...formularioError,
          password_confirmacion: "El nuevo password ingresado no coincide con la casilla anterior.",
        });
    }
    setFormulario({ ...formulario, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (!store.usuarioAutenticado.username) {
      setTimeout(() => navigate("/"), 1);
      return <div>No tienes acceso a la pagna</div>;
    }
  }, [store.usuarioAutenticado.username]);

  return (
    <Layout>
      <Row className="mt-5 me-lg-5 mb-lg-5 shadow" style={{ backgroundColor: "#90C0F0" }}>
        <Col className="p-4 p-md-5" lg={7}>
          <div className="px-md-5 px-lg-0 px-xl-5">
            <StaticImage src="../images/ondas-agua.png" width={110} className="mb-3" alt="" />
            <h1 className="registro-subtitulo mb-4">Cambiar contraseña</h1>
            <Form id="login_formulario" onSubmit={handleSubmit}>
              <span style={{ color: "red", fontWeight: "bold" }}>{validacion}</span>
              <Form.Group className="mb-3" controlId="txtPasswordActual">
                <Form.Control
                  required={true}
                  onChange={hadleChange}
                  name="password_actual"
                  type="password"
                  placeholder="Tu Password actual"
                  isInvalid={formularioError.password_actual.length !== 0}
                />
                <Form.Control.Feedback type="invalid">
                  Ingresa al menos 8 caracteres con digito, letras mayuscula y minuscula.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="txtPasswordNuevo1">
                <Form.Control
                  required={true}
                  onChange={hadleChange}
                  name="password_nuevo"
                  type="password"
                  placeholder="Tu nuevo Password"
                  isInvalid={formularioError.password_nuevo.length !== 0}
                />
                <Form.Control.Feedback type="invalid">
                  Ingresa al menos 8 caracteres con digito, letras mayuscula y minuscula.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="txtPassword">
                <Form.Control
                  required={true}
                  onChange={hadleChange}
                  name="password_confirmacion"
                  type="password"
                  placeholder="Confirma tu Password"
                  isInvalid={formularioError.password_confirmacion.length !== 0}
                />
                <Form.Control.Feedback type="invalid">{formularioError.password_confirmacion}</Form.Control.Feedback>
              </Form.Group>
              <Row className="mb-4 text-center">
                <Col>
                  <Button type="submit" className="btn-blanco float-end">
                    {loading ? "Procesando.." : "Cambiar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col className="p-0" lg={5}>
          <StaticImage
            src="../images/tomando-foto-comida.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
}
